import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'informed';

import { EmployerBenefitsCategory, StatusUpdateHeader } from '../../molecules';
import { resetButton } from '../../styles';
import { Loading } from '../../atoms';

const EmployerBenefits = ({ data, isLoading, saveBenefits }) => {
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    if (isLoading) {
        return <Loading />;
    }
    if (!data || !data.length) {
        return null;
    }
    return (
        <>
            <ViewCollapseAllWrapper>
                <ViewCollapseAll onClick={() => setIsAllExpanded(!isAllExpanded)}>{isAllExpanded ? 'Collapse all' : 'View all'}</ViewCollapseAll>
                <StatusUpdateHeader />
            </ViewCollapseAllWrapper>
            <Form key="employerBenefitsForm" onSubmit={saveBenefits} style={{ marginBottom: '30px' }} allowEmptyStrings>
                {({ formState, formApi }) =>
                    data.map(item => (
                        <EmployerBenefitsCategory
                            isAllExpanded={isAllExpanded}
                            key={item.category}
                            formState={formState}
                            handleSubmit={() => formApi.submitForm()}
                            category={item}
                        />
                    ))
                }
            </Form>
        </>
    );
};

const ViewCollapseAllWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ViewCollapseAll = styled.button`
    ${resetButton};
    font-family: Montserrat;
    font-size: 1rem;
    text-align: right;
    color: #0890a8;
    font-weight: 500;
`;

EmployerBenefits.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    saveBenefits: PropTypes.func,
    employer: PropTypes.object
};

export default EmployerBenefits;
