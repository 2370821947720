import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../atoms';

const Status = ({ status, employerLogo }) => {
    if (!status) {
        return null;
    }
    return getStatus(employerLogo)[status];
};

const getStatus = employerLogo => ({
    Review: (
        <Review>
            <StyledIcon icon="ExclamationTriangle" /> Review{' '}
        </Review>
    ),
    Draft: (
        <Saved>
            <Logo src={employerLogo} alt="Logo" /> Saved{' '}
        </Saved>
    ),
    PendingApproval: <PendingApproval employerLogo={employerLogo} />,
    Published: (
        <Published>
            <StyledIcon icon="Check" /> Published{' '}
        </Published>
    )
});

const PendingApproval = ({ employerLogo }) => {
    return (
        <Saved>
            <Logo src={employerLogo} alt="Logo" /> <Updated> Updated </Updated>{' '}
        </Saved>
    );
};

const Logo = ({ src, alt, style }) => {
    return (
        <LogoContainer>
            <LogoImage src={src} alt={alt} style={style} />
        </LogoContainer>
    );
};

const Review = styled.div.attrs({
    role: 'status'
})`
    color: #f90000;
`;

const Saved = styled.div.attrs({
    role: 'status'
})`
    display: flex;
`;

const StyledIcon = styled(Icon)`
    font-size: 14px;
`;

const Updated = styled.span`
    text-align: left;
    color: #e31c79;
`;

const Published = styled.div.attrs({
    role: 'status'
})`
    color: #498306;
    font-size: 9px;
`;

const LogoContainer = styled.div`
    height: 1rem;
    margin-right: 3px;
`;

const LogoImage = styled.img`
    max-width: 2rem;
    max-height: 100%;
    vertical-align: middle;
    margin: 0 auto;
`;

export default Status;
