import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useData } from './index';

const EmployerBenefitsContext = createContext();

const EmployerBenefitsProvider = ({ children, id, getApi, saveApi, employerData, approver }) => {
    const { data: employerBenefits, setData: setEmployerBenefits, isLoading: areBenefitsLoading, error: benefitsError } = useData(getApi, id, []);

    const [isApiLoading, setIsApiLoading] = useState(false);
    const [isApiSuccessful, setIsApiSuccessful] = useState(null);
    const [apiError, setApiError] = useState(null);

    const employer = employerData ? employerData : employerBenefits && employerBenefits.employer;
    const benefits = employerBenefits;

    const value = useMemo(
        () => ({
            id,
            employer,
            benefits,
            areBenefitsLoading,
            benefitsError,
            apiError,
            isApiLoading,
            isApiSuccessful,
            setEmployerBenefits,
            setIsApiLoading,
            setIsApiSuccessful,
            setApiError,
            getApi,
            saveApi,
            approver
        }),
        [id, employer, benefits, areBenefitsLoading, benefitsError, apiError, isApiLoading, isApiSuccessful, setEmployerBenefits, getApi, saveApi, approver]
    );

    return <EmployerBenefitsContext.Provider value={value}> {children} </EmployerBenefitsContext.Provider>;
};

const useEmployerBenefits = () => {
    const employerBenefitsContext = useContext(EmployerBenefitsContext);

    if (employerBenefitsContext === undefined) {
        throw new Error('useEmployerBenefits must be used within a EmployerBenefitsProvider');
    }

    const {
        id,
        employer,
        benefits,
        areBenefitsLoading,
        benefitsError,
        apiError,
        isApiLoading,
        isApiSuccessful,
        setEmployerBenefits,
        setIsApiLoading,
        setIsApiSuccessful,
        setApiError,
        getApi,
        saveApi,
        approver
    } = employerBenefitsContext;

    const refreshBenefits = useCallback(() => useData.apiCall(getApi, setEmployerBenefits, setApiError, setIsApiLoading)(id), [
        getApi,
        id,
        setApiError,
        setEmployerBenefits,
        setIsApiLoading
    ]);

    const saveBenefits = useCallback(
        values => {
            const result = { responses: [], approver: approver };
            for (const i in values) {
                const statementValue = values[i].value;
                const notes = values[i].notes;

                const isArray = Array.isArray(statementValue);

                if (!isArray) {
                    result.responses.push({
                        // We need to decode the base64 statement id before making the api call
                        statementId: atob(i),
                        value: statementValue,
                        notes: notes
                    });
                    continue;
                }

                const items = [];
                let isObject = false;
                for (const j in statementValue) {
                    const item = statementValue[j];
                    if (typeof item === 'object') {
                        isObject = true;
                        if ('year' in item) {
                            if (item.value === undefined || item.value === null) {
                                continue;
                            }
                            items.push(item.year + '~' + item.value);
                        }
                        if ('antecedent' in item) {
                            if (!item.antecedent || !item.consequent) {
                                continue;
                            }
                            items.push(item.antecedent + '~' + item.consequent);
                        }
                    } else if (j === 'notes') {
                        continue;
                    } else {
                        items.push(item);
                    }
                }

                if (isObject && items.length === 0) {
                    continue;
                }

                result.responses.push({
                    statementId: atob(i),
                    value: items.length > 0 ? items.join('|') : null,
                    notes: notes
                });
            }

            const onStart = () => {
                setIsApiSuccessful(false);
                setApiError(null);
            };

            const onSuccess = () => {
                setIsApiSuccessful(true);
            };

            useData
                .apiCall(
                    (id, result) => saveApi(id, result),
                    onSuccess,
                    setApiError,
                    setIsApiLoading,
                    onStart
                )(id, result)
                .then(() => {
                    refreshBenefits();
                });
        },
        [id, refreshBenefits, saveApi, setApiError, setIsApiLoading, setIsApiSuccessful, approver]
    );

    const getFollowUpStatements = (statement, formState) => {
        const followUpStatements = [];
        !!statement.followUpStatements &&
            statement.followUpStatements.forEach(followUpStatement => {
                if (
                    !followUpStatement.followUpForAnswer ||
                    statementHasOppositeFollowupAnswer(statement, followUpStatement, formState) ||
                    statementStartsWithFollowupAnswer(statement, followUpStatement, formState)
                ) {
                    followUpStatements.push(followUpStatement);
                }
            });
        return followUpStatements;
    };

    return useMemo(
        () => ({
            id,
            employer,
            benefits,
            areBenefitsLoading,
            benefitsError,
            apiError,
            isApiLoading,
            isApiSuccessful,
            saveBenefits,
            refreshBenefits,
            getFollowUpStatements
        }),
        [id, employer, benefits, areBenefitsLoading, benefitsError, apiError, isApiLoading, isApiSuccessful, saveBenefits, refreshBenefits]
    );
};

const statementHasOppositeFollowupAnswer = (statement, followUpStatement, formState) => {
    const currentValue = formState.values[btoa(statement.id)] ? formState.values[btoa(statement.id)].value : null;

    return (
        followUpStatement.followUpForAnswer &&
        String(followUpStatement.followUpForAnswer).startsWith('!') &&
        (!String(currentValue) || String(followUpStatement.followUpForAnswer).substr(1) !== String(currentValue))
    );
};

const statementStartsWithFollowupAnswer = (statement, followUpStatement, formState) => {
    const currentValue = formState.values[btoa(statement.id)] ? formState.values[btoa(statement.id)].value : null;

    return (
        followUpStatement.followUpForAnswer &&
        String(currentValue)
            .toLowerCase()
            .startsWith(String(followUpStatement.followUpForAnswer).toLowerCase())
    );
};

export { EmployerBenefitsProvider, useEmployerBenefits };
