import moment from 'moment';
import { useApproveBenefits, useEmployerBenefits } from '../../hooks';
import React from 'react';
import { StyledCheckbox } from '../../atoms';
import { Approve, LastUpdated, StatusContainer, Wrapper } from './StatusUpdateStyles';
import Status from './Status';
import styled from 'styled-components';

const StatusUpdate = ({ statement, show }) => {
    let dateUpdated = '';
    let status = '';

    if (show) {
        if (statement && statement.responses && statement.responses.length > 0) {
            dateUpdated = moment(statement.responses[0].createdDate).format('YYYY-MM-DD');
            status = statement.responses[0].status;
        } else if (statement && statement.responseInputType === 'Checkbox' && statement.options.length === 1) {
            status = '';
        } else {
            status = 'Review';
        }
    }

    const { employer } = useEmployerBenefits();
    return (
        <Wrapper style={{ marginTop: '15px' }}>
            <LastUpdated>{dateUpdated}</LastUpdated>
            <StatusContainer>
                <Status status={status} employerLogo={employer && employer.logo} />
            </StatusContainer>
            {status === 'PendingApproval' && <ApproveCheckbox statement={statement} />}
        </Wrapper>
    );
};

const ApproveCheckbox = ({ statement }) => {
    const { isApproved, toggleApprove } = useApproveBenefits();

    const isChecked = isApproved(statement);
    const onClickApprove = () => toggleApprove(statement.responses[0].id);

    return (
        <Approve>
            <CheckboxWrapper onClick={onClickApprove}>
                <HiddenInput type="checkbox" readOnly checked={isChecked} />
                <StyledCheckbox />
            </CheckboxWrapper>
        </Approve>
    );
};

const HiddenInput = styled.input`
    position: absolute;
    left: -1rem;
    opacity: 0;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;

    input[type='checkbox'] {
        &:focus + div::before {
            content: '';
        }

        &:checked + div > svg {
            display: block;
        }
    }
`;

export default StatusUpdate;
