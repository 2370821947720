import { useApproveBenefits } from '../../hooks';
import { Button, Icon, Spinner } from '../../atoms';
import React from 'react';
import styled from 'styled-components';
import { Approve, statusStyles, updatedStyles, Wrapper } from './StatusUpdateStyles';

const StatusUpdateHeader = () => {
    return (
        <Wrapper style={{ alignItems: 'center' }}>
            <LastUpdatedHeader>Last Updated</LastUpdatedHeader>
            <StatusHeader>Status</StatusHeader>
            <ApproveButton />
        </Wrapper>
    );
};

const ApproveButton = () => {
    const { approveThenRefreshBenefits, isApprovalLoading, isApprovalSuccessful, canApproveBenefits } = useApproveBenefits();

    return (
        <Approve>
            <StyledButton onClick={approveThenRefreshBenefits} disabled={!canApproveBenefits} theme="secondary">
                {isApprovalLoading && <Spinner />}
                {isApprovalSuccessful && <Icon icon="Check" />}
                {!(isApprovalLoading || isApprovalSuccessful) && <span>Approve</span>}
            </StyledButton>
        </Approve>
    );
};

const StatusHeader = styled.div`
    ${statusStyles}
`;

const LastUpdatedHeader = styled.div`
    ${updatedStyles}
`;

const StyledButton = styled(Button)`
    padding: 5px;
    font-size: 12px;
    height: 30px;
    min-width: 75px;
`;

export default StatusUpdateHeader;
