import React, { useMemo } from 'react';
import axios from 'axios';
import config from '../../config';
import { getRegion } from '../../helpers';
import { useAuth0 } from '@auth0/auth0-react';

const BASE_URL = `${config.api.url}api/`;
const BASE_URL_V2 = `${config.api.url}api/v2/`;

async function sendRequest({ getAccessToken, uri, method, data, useV2, removeRegion = false }) {
    const region = getRegion();
    let token = '';
    // Only get the access token for auth api calls
    if (getAccessToken) {
        token = await getAccessToken();
    }

    let url = `${useV2 ? BASE_URL_V2 : BASE_URL}${region}/${uri}`;
    if (removeRegion) {
        url = `${useV2 ? BASE_URL_V2 : BASE_URL}${uri}`;
    }

    const headers = {
        'content-type': 'application/json'
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    let requestConfig = {
        method,
        url: url,
        headers: headers
    };

    if (method === 'post' || method === 'put' || data) {
        requestConfig = { ...requestConfig, data };
    }

    return axios(requestConfig);
}

function downloadRequestWithRegion(getAccessToken, uri, queryStr, method, fileName) {
    const region = getRegion();

    return downloadRequest(getAccessToken, `${BASE_URL}${region}/${uri}?${queryStr}`, method, fileName);
}

async function downloadRequest(getAccessToken, uri, method, fileName) {
    let token = '';

    // Only get the access token for auth api calls
    if (getAccessToken) {
        token = await getAccessToken();
    }

    const headers = {
        'content-type': 'application/json',
        responseType: 'application/octet-stream'
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return axios({
        method,
        url: uri,
        dataType: 'binary',
        processData: false,
        responseType: 'arraybuffer',
        headers: headers
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });
}

function get(getAccessToken, uri, id, useV2 = false) {
    return sendRequest({ getAccessToken, uri: `${uri}${id !== undefined ? `/${id}` : ''}`, method: 'get', useV2 });
}

function getRegionless(getAccessToken, uri, id, useV2 = false) {
    return sendRequest({ getAccessToken, uri: `${uri}${id !== undefined ? `/${id}` : ''}`, method: 'get', useV2, removeRegion: true });
}

function getAll(getAccessToken, uri, page, queryStr = '', useV2 = false) {
    return sendRequest({ getAccessToken, uri: `${uri}/?page=${page || 1}${queryStr}`, method: 'get', useV2 });
}

function getAllSorted(getAccessToken, uri, page, queryStr = '', sortByKey = '', sortIsDescending = true, useV2 = false) {
    return sendRequest({ getAccessToken, uri: `${uri}/?page=${page || 1}${queryStr}&sortByKey=${sortByKey}&sortIsDescending=${sortIsDescending}`, method: 'get', useV2 });
}

function post(getAccessToken, uri, data, useV2 = false) {
    return sendRequest({ getAccessToken, uri, method: 'post', data, useV2 });
}

function postRegionless(getAccessToken, uri, data, useV2 = false) {
    return sendRequest({ getAccessToken, uri, method: 'post', data, useV2, removeRegion: true });
}

function put(getAccessToken, uri, id, data, useV2 = false) {
    return sendRequest({ getAccessToken, uri: `${uri}/${id}`, method: 'put', data, useV2 });
}

function del(getAccessToken, uri, id, useV2 = false) {
    return sendRequest({ getAccessToken, uri: `${uri}/${id}`, method: 'delete', useV2 });
}

function delWithPayload(getAccessToken, uri, id, data, useV2 = false, removeRegion = false) {
    return sendRequest({ getAccessToken, uri: `${uri}/${id}`, method: 'delete', data, useV2, removeRegion });
}

const getApiCalls = getAccessTokenSilently => {
    return {
        getAllWidgets: page => getAll(getAccessTokenSilently, 'feed/widget', page),
        getWidget: id => get(getAccessTokenSilently, 'feed/widget', id),
        addWidget: data => post(getAccessTokenSilently, 'feed/widget', data),
        updateWidget: (id, data) => put(getAccessTokenSilently, 'feed/widget', id, data),
        deleteWidget: id => del(getAccessTokenSilently, 'feed/widget', id),
        getAllEmployers: (page, queryStr) => getAll(getAccessTokenSilently, 'employer', page, queryStr, true),
        getEmployer: id => get(getAccessTokenSilently, 'employer', id, true),
        addEmployer: data => post(getAccessTokenSilently, 'employer', data, true),
        updateEmployer: (id, data) => put(getAccessTokenSilently, 'employer', id, data, true),
        removeEmployerImage: (id, data) => post(getAccessTokenSilently, `employer/remove-image/${id}`, data, true),
        deleteEmployer: id => del(getAccessTokenSilently, 'employer', id, true),
        getAllEmployerIndustries: id => get(getAccessTokenSilently, 'employer/industries', id, true),
        getEmployerIntegrationInfo: slug => get(getAccessTokenSilently, 'employer/employerIntegrationInfo', slug, true),
        uploadContentFile: data => post(getAccessTokenSilently, `contentfile`, data),
        getEmployerPageContentBlocks: slug => get(getAccessTokenSilently, 'contentblock/employer', slug),
        orderEmployerPageContentBlocks: (slug, pageTab, sidebar, blocks) =>
            put(getAccessTokenSilently, `contentblock/employer-order`, slug, { pageTab, sidebar, blocks }),
        publishEmployerPageContentBlocks: slug => put(getAccessTokenSilently, 'contentblock/employer-publish', slug),
        getEmployerPageContentBlock: blockId => get(getAccessTokenSilently, 'contentblock', blockId),
        addEmployerPageContentBlock: payload => post(getAccessTokenSilently, 'contentblock', payload),
        updateEmployerPageContentBlock: (blockId, payload) => put(getAccessTokenSilently, 'contentblock', blockId, payload),
        deleteEmployerPageContentBlock: blockId => del(getAccessTokenSilently, 'contentblock', blockId),
        getEmployerFeatures: employerId => get(getAccessTokenSilently, 'employerfeatures', employerId),
        updateEmployerFeatures: (employerId, body) => post(getAccessTokenSilently, `employerfeatures/${employerId}`, body),
        getAllEndorsements: () => get(getAccessTokenSilently, 'endorsement'),
        addEndorsement: payload => post(getAccessTokenSilently, 'endorsement', payload),
        updateEndorsement: (id, payload) => put(getAccessTokenSilently, 'endorsement', id, payload),

        //Employer Features
        getEmployerPerk: employerId => getRegionless(getAccessTokenSilently, `employerperk`, employerId),
        getEmployerPerkIcons: () => getRegionless(getAccessTokenSilently, `employerperk/icons`),
        getEmployerPerks: employerId => getRegionless(getAccessTokenSilently, `employerperk/portal`, employerId),
        reorderEmployerPerks: (employerId, payload) => postRegionless(getAccessTokenSilently, `employerperk/re-order/${employerId}`, payload, false),
        deleteEmployerPerk: (employerId, payload) => delWithPayload(getAccessTokenSilently, `employerperk`, employerId, payload, false, true),
        saveEmployerPerk: payload => postRegionless(getAccessTokenSilently, `employerperk`, payload, false, true),

        // Employer Benefits
        getEmployerBenefits: id => get(getAccessTokenSilently, 'employerbenefits', id),
        saveEmployerBenefits: (id, payload) => post(getAccessTokenSilently, `employerbenefits/${id}`, payload),
        approveEmployerBenefits: (id, payload) => post(getAccessTokenSilently, `employerbenefits/${id}/approve`, payload),
        getEmployerFiles: id => get(getAccessTokenSilently, `employerbenefits/${id}/files`),
        uploadEmployerFile: (id, payload) => post(getAccessTokenSilently, `employerbenefits/${id}/uploadFile`, payload),
        removeEmployerFile: (id, fileId) => del(getAccessTokenSilently, `employerbenefits/${id}/removeFile`, fileId),
        downloadEmployerFile: (id, fileId, fileName) =>
            downloadRequestWithRegion(getAccessTokenSilently, `employerbenefits/${id}/downloadFile/${fileId}`, null, 'post', fileName),

        getAllJobs: (page, queryStr) => getAll(getAccessTokenSilently, 'job', page, queryStr),
        getJob: id => get(getAccessTokenSilently, 'job', id),
        addJob: data => post(getAccessTokenSilently, 'job', data),
        updateJob: (id, data) => put(getAccessTokenSilently, 'job', id, data),
        repostJob: id => put(getAccessTokenSilently, 'job/repost', id),
        deleteJob: id => del(getAccessTokenSilently, 'job', id),
        getDisciplines: () => get(getAccessTokenSilently, 'job/classifications'),
        getAllUsers: (page, queryStr) => getAll(getAccessTokenSilently, 'user', page, queryStr),
        getUser: id => get(getAccessTokenSilently, 'user', id),
        addUser: data => post(getAccessTokenSilently, 'user', data),
        updateUser: (id, data) => put(getAccessTokenSilently, 'user', id, data),
        deleteUser: id => del(getAccessTokenSilently, 'user', id),
        getAllApplications: (page, queryStr, sortByKey, sortIsDescending) =>
            getAllSorted(getAccessTokenSilently, 'application/manage', page, queryStr, sortByKey, sortIsDescending),
        getApplication: id => get(getAccessTokenSilently, 'application', id),
        updateApplication: (id, data) => put(getAccessTokenSilently, 'application/manage', id, data),

        getSuggestedJobLocations: queryStr => get(getAccessTokenSilently, `job/location-autocomplete?${queryStr}`),

        getJobAnalysis: body => post(getAccessTokenSilently, 'jobanalyzer/genderbias/resolve', body),

        getAuthClaims: () => get(getAccessTokenSilently, 'auth', 'claims'),
        getFile: (fileId, fileName) => downloadRequest(getAccessTokenSilently, `${BASE_URL}download/${fileId}`, 'post', fileName),
        getJobAnalytics: (page, queryStr) => getAll(getAccessTokenSilently, 'analytics', page, queryStr),
        generateAnalytics: queryStr => get(getAccessTokenSilently, `analytics/generate?${queryStr}`),
        pingReport: fileId => get(getAccessTokenSilently, `analytics/ping/${fileId}`),
        getDownloadUrl: fileId => `${BASE_URL}${getRegion()}/analytics/download/${fileId}`,
        getAllJobFilters: () => get(getAccessTokenSilently, 'job/jobfilters'),

        getSuggestedAddress: queryStr => get(getAccessTokenSilently, `content/location-autocomplete?${queryStr}`),
        // JobAutomation
        getAllJobAutomations: page => getAll(getAccessTokenSilently, 'jobautomation', page),
        getJobAutomation: employerId => get(getAccessTokenSilently, 'jobautomation', employerId),
        sendJobAutomationDryRun: payload => postRegionless(getAccessTokenSilently, 'scraper/dry-run/jobAutomation', payload),
        getJobAutomationDryRunResult: id => getRegionless(getAccessTokenSilently, `scraper/dry-run?id=${id}`),
        runJobAutomation: employerId => postRegionless(getAccessTokenSilently, `scraper/runsite?employerId=${employerId}`),
        toggleJobAutomationStatus: (employerId, isDisabled) => post(getAccessTokenSilently, `jobautomation/toggle-status/${employerId}/${isDisabled}`),

        downloadEmployerJobReport: (queryStr, fileName) => downloadRequestWithRegion(getAccessTokenSilently, 'report/employerjobs/download', queryStr, 'get', fileName)
    };
};

const ApiClientContext = React.createContext();

const ApiClientProvider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const apiClient = useMemo(() => {
        return getApiCalls(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    return <ApiClientContext.Provider value={{ apiClient }}>{children}</ApiClientContext.Provider>;
};

export { ApiClientProvider, ApiClientContext, getApiCalls };
