import { useEmployerBenefits } from '../../hooks';
import moment from 'moment';
import React from 'react';
import { Approve, LastUpdated, StatusContainer, Wrapper } from './StatusUpdateStyles';
import Status from './Status';

const StatusUpdateCategory = ({ category, show, formState }) => {
    const { employer, getFollowUpStatements } = useEmployerBenefits();

    const main = category.statements;
    const followup = category.statements.flatMap(statement => getFollowUpStatements(statement, formState));
    const allStatementsInCategory = [...main, ...followup];

    const dateUpdated = getDateUpdatedForCategory(allStatementsInCategory, show);
    const status = getStatusForCategory(allStatementsInCategory, show);

    return (
        <Wrapper>
            <LastUpdated>{dateUpdated}</LastUpdated>
            <StatusContainer>
                <Status status={status} employerLogo={employer && employer.logo} />
            </StatusContainer>
            <Approve />
        </Wrapper>
    );
};

const getStatusForCategory = (allStatementsInCategory, show) => {
    let status = '';

    if (!show) return status;

    const categoryNeedsReview = allStatementsInCategory.some(statement => {
        return !(statement && statement.responses && statement.responses.length > 0);
    });

    if (categoryNeedsReview) {
        status = 'Review';
    }

    const categoryIsUpdated = allStatementsInCategory.some(statement => {
        return statement && statement.responses && statement.responses.length > 0 && statement.responses.some(x => x.status === 'PendingApproval');
    });

    if (categoryIsUpdated) {
        status = 'PendingApproval';
    }

    return status;
};

const getDateUpdatedForCategory = (allStatementsInCategory, show) => {
    let dateUpdated = '';

    if (!show) return dateUpdated;

    const statementsWithResponses = allStatementsInCategory.filter(statement => {
        return statement && statement.responses && statement.responses.length > 0;
    });

    if (statementsWithResponses.length) {
        const max = statementsWithResponses.reduce((x, y) => {
            if (x.responses.length === 0) {
                return y;
            }
            if (y.responses.length === 0) {
                return x;
            }
            return moment(x.responses[0].createdDate).isAfter(y.responses[0].createdDate) ? x : y;
        });
        dateUpdated = moment(max.responses[0].createdDate).format('YYYY-MM-DD');
    }

    return dateUpdated;
};

export default StatusUpdateCategory;
