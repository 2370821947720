import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { setupOptions } from './setupOptions';
import { CheckboxGroupInput, PercentageFieldsGroup, RadioGroupInput, RatioFieldsGroup, SelectInput, TextInput, TextInputGroup, Tooltip } from '../../atoms';
import { useEmployerBenefits } from '../../hooks';
import { StatusUpdate } from '../../molecules';

const EmployerBenefitsStatement = ({ statement, handleSubmit, formState, isExpanded }) => {
    const { getFollowUpStatements } = useEmployerBenefits();
    const initialValue = !!statement.responses && statement.responses.length > 0 ? statement.responses[0].value : null;
    const notes = !!statement.responses && statement.responses.length > 0 ? statement.responses[0].notes : null;
    const options = useMemo(() => setupOptions(statement), [statement]);
    // We need to base64 encode the statement id as the Informed library has special handling for some characters in field names.
    // By b64ing the id, we can still use certain characters without Informed interfering.
    const base64StatementId = btoa(statement.id);

    const name = base64StatementId + '.value';
    const displayBeforeField = statement.responseDisplayFormat && statement.responseDisplayFormat.match(/(\$")(.*?)(\[{)/);
    const showDisplayBeforeField = statement.responseDisplayFormat && displayBeforeField;
    const showDisplayAfterField = statement.options && statement.options.length;

    const followUpStatements = getFollowUpStatements(statement, formState).map(followUpStatement => {
        return (
            <EmployerBenefitsStatement
                key={followUpStatement.id}
                handleSubmit={handleSubmit}
                statement={followUpStatement}
                isExpanded={isExpanded}
                formState={formState}
            />
        );
    });

    return (
        <>
            <Container>
                <EmployerBenefitsStatementWrapper>
                    <EmployerBenefitsStatementLabel>
                        {statement.label}
                        {statement.portalDefinition && <Tooltip text={statement.portalDefinition} iconSize="small" />}
                    </EmployerBenefitsStatementLabel>
                    <EmployerBenefitsStatementResponse>
                        {statement.responseInputType === 'Dropdown' && <SelectInput name={name} initialValue={initialValue} options={options} onBlur={handleSubmit} />}

                        {statement.responseInputType === 'RadioGroup' && (
                            <RadioGroupInput name={name} initialValue={initialValue} options={options} onChange={handleSubmit} />
                        )}

                        {statement.responseInputType === 'Number' && (
                            <NumberContainer>
                                {statement.responseDisplayFormat && <FieldDisplayText>{showDisplayBeforeField && displayBeforeField[2]} </FieldDisplayText>}
                                <TextInput
                                    style={
                                        (showDisplayBeforeField || showDisplayAfterField) && {
                                            width: '80px',
                                            flexGrow: '2'
                                        }
                                    }
                                    name={name}
                                    initialValue={initialValue}
                                    onBlur={handleSubmit}
                                    type="number"
                                />
                                {showDisplayAfterField && <FieldDisplayText>{statement.options[0].name}</FieldDisplayText>}
                            </NumberContainer>
                        )}

                        {statement.responseInputType === 'Checkbox' && (
                            <CheckboxGroupInput name={name} initialValue={initialValue && initialValue.split('|')} options={options} onChange={handleSubmit} />
                        )}

                        {statement.responseInputType === 'PercentageFields' && (
                            /* List of  */
                            <PercentageFieldsGroup
                                name={name}
                                initialValue={
                                    !!initialValue &&
                                    initialValue.split('|').map(item => ({
                                        year: item.split('~')[0] || '',
                                        value: item.split('~')[1] || ''
                                    }))
                                }
                                options={options}
                                onChange={handleSubmit}
                            />
                        )}

                        {statement.responseInputType === 'Ratio' && (
                            <RatioFieldsGroup
                                name={name}
                                initialValue={
                                    !!initialValue &&
                                    initialValue.split('|').map(item => ({
                                        antecedent: item.split('~')[0] || '',
                                        consequent: item.split('~')[1] || ''
                                    }))
                                }
                                onChange={handleSubmit}
                            />
                        )}

                        {statement.responseInputType === 'CustomList' && (
                            <TextInputGroup name={name} maxItems={10} initialValue={initialValue ? initialValue.split('|') : []} onChange={handleSubmit} />
                        )}

                        {!!statement.hasNotes && (
                            <CommentWrapper>
                                <CommentLabel htmlFor={base64StatementId + '.notes'}>Comment</CommentLabel>
                                <TextInput rows={3} id={base64StatementId + '.notes'} name={base64StatementId + '.notes'} initialValue={notes} onBlur={handleSubmit} />
                            </CommentWrapper>
                        )}
                    </EmployerBenefitsStatementResponse>
                </EmployerBenefitsStatementWrapper>
                <StatusUpdate statement={statement} show={isExpanded} />
            </Container>
            {followUpStatements}
        </>
    );
};

const Container = styled.div.attrs({
    role: 'row'
})`
    display: flex;
`;

const EmployerBenefitsStatementWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    flex-grow: 1;
`;

const EmployerBenefitsStatementLabel = styled.span.attrs({
    role: 'rowheader'
})`
    font-weight: 400;
    font-size: 14px;
    flex: 50%;
    margin-right: 10px;
    max-width: 268px;
`;

const EmployerBenefitsStatementResponse = styled.div.attrs({
    role: 'cell'
})`
    flex: 50%;
`;

const CommentWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const CommentLabel = styled.label`
    margin-right: 10px;
`;

const NumberContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

const FieldDisplayText = styled.span`
    margin: 10px;
`;

EmployerBenefitsStatement.propTypes = {
    statement: PropTypes.shape({
        id: PropTypes.string,
        categoryId: PropTypes.string,
        category: PropTypes.object,
        label: PropTypes.string,
        order: PropTypes.number,
        regions: PropTypes.array,
        portalDefinition: PropTypes.string,
        jobSiteDefinition: PropTypes.string,
        hasNotes: PropTypes.bool,
        responseInputType: PropTypes.string,
        responseDisplayFormat: PropTypes.string,
        useYearsOptions: PropTypes.bool,
        followUpForId: PropTypes.string,
        followUpForAnswer: PropTypes.string,
        options: PropTypes.array,
        responses: PropTypes.array,
        followUpStatements: PropTypes.array
    }),
    handleSubmit: PropTypes.func,
    formState: PropTypes.any,
    isExpanded: PropTypes.bool
};

export default EmployerBenefitsStatement;
